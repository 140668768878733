import PropTypes from 'prop-types';
import React from 'react';
import withTranslate from '../../../hoc/with-translate';
import styles from './tags-empty-state.scss';

export const TagsEmptyState = ({ t }) => <div className={styles.container}>{t('empty-state.no-tags')}</div>;

TagsEmptyState.propTypes = {
  t: PropTypes.func.isRequired,
};

export default withTranslate(TagsEmptyState);
