import classNames from 'classnames';
import PropTypes from 'prop-types';
import React from 'react';
import { connect } from '../../runtime-context';
import {
  getTagLayout,
  getTagVerticalSpacing,
  getTagHorizontalSpacing,
} from '../../../selectors/app-settings-selectors.js';
import { isTagLayoutButton } from '@wix/communities-blog-client-common/dist/src/constants/tag-constants';
import { getAppConfig } from '../../../store/app-config/app-config-selectors';
import { getTagUrl } from '../../../services/get-tag-url';
import styles from './tag.scss';

const Tag = ({ tagUrl, label, tagLayout, tagVerticalSpacing, tagHorizontalSpacing, postCount, showPostCount }) => {
  const tagLayoutButton = isTagLayoutButton(tagLayout);

  const tagStyles = {
    marginLeft: tagHorizontalSpacing,
    marginTop: tagVerticalSpacing,
  };

  const tagClassName = classNames(
    styles.tag,
    tagLayoutButton && styles.tagButton,
    styles.blogPostTagsFont,
    styles.blogPostTagsColor,
    styles.blogPostTagsBorder,
    tagLayoutButton && styles.blogPostTagsBackground,
    tagLayoutButton && styles.blogPostTagsBorderColor,
    tagLayoutButton && styles.blogPostTagsPadding,
  );

  return (
    <li className={styles.tag} style={tagStyles}>
      <a href={tagUrl} className={tagClassName}>
        {label} {showPostCount && `(${postCount})`}
      </a>
    </li>
  );
};

Tag.propTypes = {
  tagUrl: PropTypes.string.isRequired,
  label: PropTypes.string.isRequired,
  tagLayout: PropTypes.number.isRequired,
  tagVerticalSpacing: PropTypes.number.isRequired,
  tagHorizontalSpacing: PropTypes.number.isRequired,
  postCount: PropTypes.number,
  showPostCount: PropTypes.bool,
};

const mapRuntimeToProps = (state, { postCount, showPostCount, slug }) => {
  const { sectionUrl } = getAppConfig(state);

  return {
    tagLayout: getTagLayout(state),
    tagVerticalSpacing: getTagVerticalSpacing(state),
    tagHorizontalSpacing: getTagHorizontalSpacing(state),
    tagUrl: getTagUrl(sectionUrl, slug),
    showPostCount: showPostCount && !!postCount,
  };
};

export default connect(mapRuntimeToProps)(Tag);
