import { get, flowRight } from 'lodash';
import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { connect } from '../../../common/components/runtime-context';

import { SECTION_CATEGORY } from '@wix/communities-blog-client-common/dist/src/constants/wix-params';
import { getAppConfig } from '../../../common/store/app-config/app-config-selectors';
import { getIsTagLoaded } from '../../../common/store/is-loaded/is-loaded-selectors';
import { getIsTagLoading } from '../../../common/store/is-loading/is-loading-selectors';
import { getTagUrl } from '../../../common/services/get-tag-url';
import { getSortedPostsByTagId, getPostsByTagIdAndPage } from '../../../common/selectors/post-selectors';
import { isSite } from '../../../common/selectors/view-mode-selectors';
import { isExcludePostContentSupported } from '../../../common/selectors/layout-selectors';
import withAuth from '../../../common/hoc/with-auth';
import withLayoutProps from '../../../common/hoc/with-layout-props';
import withPaginationSettings from '../../hoc/with-pagination-settings';
import withPermissions from '../../../common/hoc/with-permissions';
import withTranslate from '../../../common/hoc/with-translate';
import AnimatedLoader from '../../../common/components/animated-loader';
import AppLoaded from '../../../common/components/app-loaded';
import { getTagBySlug } from '../../../common/selectors/tags-selectors';
import Page from '../../../common/components/page';
import Pagination from '../../../common/containers/pagination';
import PostList from '../../components/post-list';
import PostListProGallery from '../../../common/components/post-list-pro-gallery';
import { EmptyLayout } from '../../../common/components/layout';
import { createPageUrl, handleNavigation } from '../../../common/services/pagination';
import { isLayoutProGallery } from '@wix/communities-blog-client-common/dist/src/constants/layout-types';
import { getIsMobile } from '../../../common/store/basic-params/basic-params-selectors';
import { EXPERIMENT_MOVE_CREATE_POST_BUTTON } from '@wix/communities-blog-experiments';
import withExperiment from '../../../common/hoc/with-experiment';
import { isCreatePostButtonVisible } from '../../../common/selectors/blog-menu-button-selectors';

const Layout = EmptyLayout;

export class TagsPage extends Component {
  state = {
    isAnimated: !this.props.allPosts.length,
  };

  componentWillUnmount() {
    this.props.setIsLoaded('tag', this.props.tagId, false);
  }

  componentDidUpdate({ isAuthenticated, isBlocked, pageSize, excludeContent }) {
    const { tagId } = this.props;
    if (
      this.props.isAuthenticated !== isAuthenticated ||
      this.props.isBlocked !== isBlocked ||
      this.props.pageSize !== pageSize ||
      (excludeContent && !this.props.excludeContent)
    ) {
      this.fetchPosts(tagId);
    }
  }

  fetchPosts(tagId, page = this.props.page) {
    if (this.props.isBlocked) {
      return;
    }

    this.props.fetchTagPosts({ tagId, page, excludeContent: this.props.excludeContent });
  }

  loadMore = page => this.fetchPosts(this.props.tagId, page);

  renderPosts() {
    const {
      currentPagePosts,
      allPosts,
      entityCount,
      isTagLoaded,
      isTagLoading,
      showCreatePostAction,
      page,
      layoutType,
      showPagination,
    } = this.props;

    if (isLayoutProGallery(layoutType)) {
      return (
        <PostListProGallery
          pageStart={page + 1}
          entityCount={entityCount}
          loadMore={showPagination ? undefined : this.loadMore}
          allPosts={showPagination ? currentPagePosts : allPosts}
          currentPagePosts={currentPagePosts}
          isLoading={isTagLoading}
          showCreatePostAction={showCreatePostAction}
          section={SECTION_CATEGORY}
        />
      );
    }

    return (
      <PostList
        pageStart={page + 1}
        layoutType={layoutType}
        entityCount={entityCount}
        loadMore={showPagination ? undefined : this.loadMore}
        allPosts={showPagination ? currentPagePosts : allPosts}
        currentPagePosts={currentPagePosts}
        isLoading={isTagLoading}
        isLoaded={isTagLoaded}
        showCreatePostAction={showCreatePostAction}
      />
    );
  }

  renderPagination() {
    const { entityCount, pageSize, page, tagUrl, isSite, tagPath } = this.props;

    return (
      <Pagination
        page={page}
        pageSize={pageSize}
        entityCount={entityCount}
        createPageUrl={page => createPageUrl(page, tagUrl)}
        handleNavigation={handleNavigation(tagPath, isSite)}
      />
    );
  }

  render() {
    const { isTagLoaded, isTagLoading, allPosts, showPagination } = this.props;

    const isLoading = isTagLoading && !isTagLoaded;

    return (
      <Page noSpacing>
        <Layout>
          <AnimatedLoader isLoading={isLoading && !allPosts.length} isAnimated={this.state.isAnimated}>
            {this.renderPosts()}
          </AnimatedLoader>
          {showPagination && !isLoading && this.renderPagination()}
          {isTagLoaded && <AppLoaded />}
        </Layout>
      </Page>
    );
  }
}

TagsPage.propTypes = {
  tagId: PropTypes.string.isRequired,
  tagUrl: PropTypes.string.isRequired,
  tagPath: PropTypes.string.isRequired,
  fetchTagPosts: PropTypes.func.isRequired,
  setIsLoaded: PropTypes.func.isRequired,
  allPosts: PropTypes.array,
  currentPagePosts: PropTypes.array,
  isAuthenticated: PropTypes.bool,
  isBlocked: PropTypes.bool,
  params: PropTypes.object,
  t: PropTypes.func,
  isTagLoading: PropTypes.bool,
  isTagLoaded: PropTypes.bool,
  isSite: PropTypes.bool,
  showCreatePostAction: PropTypes.bool.isRequired,
  layoutType: PropTypes.number.isRequired,
  excludeContent: PropTypes.bool,
  pageSize: PropTypes.number,
  entityCount: PropTypes.number,
  showPagination: PropTypes.bool,
  isMoveCreatePostButtonEnabled: PropTypes.bool,
};

const mapRuntimeToProps = (state, ownProps, actions) => {
  const tagSlug = get(ownProps, ['params', 'tagSlug']);
  const tag = getTagBySlug(state, tagSlug);
  const tagId = tag.id;

  const { sectionUrl } = getAppConfig(state);
  const tagUrl = getTagUrl(sectionUrl, tagSlug);

  const showCreatePostAction = ownProps.isMoveCreatePostButtonEnabled
    ? getIsMobile(state) && isCreatePostButtonVisible(state, ownProps.canSee)
    : isSite(state) && ownProps.canSee('create', 'post');

  return {
    tagId,
    tagPath: 'tags',
    tagUrl,
    allPosts: tagId ? getSortedPostsByTagId(state, tagId) : [],
    currentPagePosts: tagId ? getPostsByTagIdAndPage(state, tagId, ownProps.page) : [],
    isTagLoading: getIsTagLoading(state, tagId),
    isTagLoaded: getIsTagLoaded(state, tagId),
    isSite: isSite(state),
    showCreatePostAction,
    excludeContent: isExcludePostContentSupported(state),
    setIsLoaded: actions.setIsLoaded,
    fetchTagPosts: actions.fetchTagPostsPromisified,
  };
};

export default flowRight(
  withPermissions,
  withPaginationSettings(SECTION_CATEGORY),
  withLayoutProps(),
  withAuth,
  withExperiment({
    isMoveCreatePostButtonEnabled: EXPERIMENT_MOVE_CREATE_POST_BUTTON,
  }),
  withTranslate,
  connect(mapRuntimeToProps),
)(TagsPage);
