import { find } from 'lodash';
import { createSelector } from 'reselect';

export const getTags = state => state.tags;

const getTagSlug = (state, tagSlug) => tagSlug;

export const getTagBySlug = createSelector([getTags, getTagSlug], (tags, tagSlug) =>
  find(tags, tag => tag.slug === tagSlug),
);
