import React from 'react';
import { flowRight, pick } from 'lodash';
import PropTypes from 'prop-types';
import { connect } from '../../../common/components/runtime-context';
import {
  ROUTE_HOME,
  ROUTE_404,
  ROUTE_LOGIN,
  ROUTE_CATEGORIES,
  ROUTE_CATEGORY,
  ROUTE_SEARCH,
  ROUTE_ARCHIVE,
  ROUTE_TAGS,
} from '../../constants/routes';
import { getCurrentMatch } from '../../../common/router/router-selectors';

import App from '../../../common/components/app';
import ModalRoot from '../../components/modals/modal-root';
import MessageRoot from '../../components/messages/message-root';
import CategoryMobileHeader from '../../../common/components/category-mobile-header';
import CategoryDesktopHeader from '../../../common/components/category-desktop-header';
import NotFoundPage from '../../../common/containers/not-found-page';
import FeedPage from '../feed-page';
import CategoryPage from '../category-page';
import CategoriesLink from '../../../common/components/categories-link';
import SearchMobileHeader from '../../../search/containers/search-header/search-mobile-header';
import SearchDesktopHeader from '../../../search/containers/search-header/search-desktop-header';
import SearchResultsPage from '../../../search/containers/search-results-page/search-results-page';
import LoginPage from '../../../common/containers/login-page';
import ArchivePage from '../../../archive/containers/archive-page';
import TagsPage from '../tags-page';

const WrapComponent = Component => props => (
  <App
    main={<Component params={props.params} />}
    modalRoot={<ModalRoot />}
    messageRoot={<MessageRoot />}
    mobileHeader={<CategoryMobileHeader />}
    mobileMenu={<CategoriesLink />}
    desktopHeader={<CategoryDesktopHeader />}
  />
);

const ROUTE_COMPONENT_MAP = {
  [ROUTE_404]: WrapComponent(NotFoundPage),
  [ROUTE_LOGIN]: WrapComponent(LoginPage),
  [ROUTE_CATEGORIES]: WrapComponent(CategoryPage),
  [ROUTE_CATEGORY]: WrapComponent(CategoryPage),
  [ROUTE_ARCHIVE]: WrapComponent(ArchivePage),
  [ROUTE_TAGS]: WrapComponent(TagsPage),
  [ROUTE_SEARCH]: props => (
    <App
      main={<SearchResultsPage params={props.params} />}
      // modalRoot={<ModalRoot />}
      // messageRoot={<MessageRoot />}
      mobileHeader={<SearchMobileHeader params={props.params} />}
      desktopHeader={<SearchDesktopHeader params={props.params} />}
    />
  ),
  [ROUTE_HOME]: WrapComponent(FeedPage),
};

const Router = ({ route, ...rest }) => {
  const Component = ROUTE_COMPONENT_MAP[route]
    ? ROUTE_COMPONENT_MAP[route]
    : () => <div>Route "{route}" - is not implemented</div>;
  return <Component {...rest} />;
};

Router.propTypes = {
  route: PropTypes.string,
  pathname: PropTypes.string,
  sectionUrl: PropTypes.string,
  params: PropTypes.object,
};

const mapRuntimeToProps = state => pick(getCurrentMatch(state), ['route', 'pathname', 'sectionUrl', 'params']);

export default flowRight(connect(mapRuntimeToProps))(Router);
