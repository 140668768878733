import { flowRight } from 'lodash';
import PropTypes from 'prop-types';
import React from 'react';
import { connect } from '../runtime-context';
import withTranslate from '../../hoc/with-translate';
import {
  getIsTagLabelEnabled,
  getTagAlignment,
  getTagVerticalSpacing,
  getTagHorizontalSpacing,
  getTagOrder,
  getTagShowPostCount,
  getNumberOfTags,
} from '../../selectors/app-settings-selectors.js';
import { getTagTextAlignment, sortTags } from '../../services/tag-helpers';
import Tag from './tag';
import TagsEmptyState from './tags-empty-state';
import styles from './tags.scss';

export class PostTags extends React.Component {
  render() {
    const {
      t,
      tags,
      isTagLabelEnabled,
      tagAlignment,
      tagVerticalSpacing,
      tagHorizontalSpacing,
      isPostPageTags,
      showPostCount,
      numberOfTags,
      tagOrder,
    } = this.props;

    if (!tags || !tags.length) {
      if (isPostPageTags) {
        return null;
      }

      return <TagsEmptyState />;
    }
    const containerStyles = {
      textAlign: getTagTextAlignment(tagAlignment),
    };

    const tagWrapperStyles = {
      marginLeft: -tagHorizontalSpacing,
      marginTop: -tagVerticalSpacing,
    };

    const sortedTags = sortTags(tags, tagOrder);
    const preparedTags = isPostPageTags ? sortedTags : sortedTags.slice(0, numberOfTags);

    return (
      <div style={containerStyles} className={isPostPageTags && styles.postPageTagContainer}>
        {isTagLabelEnabled && <p className={styles.tagLabel}>{t('post-page.tags-label')}</p>}
        <nav aria-label="tags" className={styles.navigation} style={tagWrapperStyles}>
          <ul>
            {preparedTags.map(({ id, slug, label, postCount }) => (
              <Tag key={id} slug={slug} label={label} showPostCount={showPostCount} postCount={postCount} />
            ))}
          </ul>
        </nav>
      </div>
    );
  }
}

PostTags.propTypes = {
  t: PropTypes.func.isRequired,
  tags: PropTypes.arrayOf(
    PropTypes.shape({
      id: PropTypes.string.isRequired,
      label: PropTypes.string.isRequired,
      slug: PropTypes.string.isRequired,
    }),
  ),
  isTagLabelEnabled: PropTypes.bool.isRequired,
  tagAlignment: PropTypes.number.isRequired,
  tagVerticalSpacing: PropTypes.number.isRequired,
  tagHorizontalSpacing: PropTypes.number.isRequired,
  isPostPageTags: PropTypes.bool,
};

const mapRuntimeToProps = (state, { isPostPageTags }) => ({
  isTagLabelEnabled: getIsTagLabelEnabled(state),
  tagAlignment: getTagAlignment(state),
  tagVerticalSpacing: getTagVerticalSpacing(state),
  tagHorizontalSpacing: getTagHorizontalSpacing(state),
  tagOrder: getTagOrder(state),
  showPostCount: getTagShowPostCount(state) && !isPostPageTags,
  numberOfTags: getNumberOfTags(state),
});

// prettier-ignore
export default flowRight(
  connect(mapRuntimeToProps),
  withTranslate,
)(PostTags);
